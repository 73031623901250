import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CommentModal = ({
  show,
  handleClose,
  newComment,
  setNewComment,
  handleAddComment,
  comments,
  selectedCommentId,
  setReplyText,
  replyText,
  handleAddReply
}) => {
  const selectedComment = comments.find(comment => comment.id === selectedCommentId);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{selectedCommentId ? 'Reply to Comment' : 'Add Comment'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {comments.map(comment => (
            <div key={comment.id}>
              <strong>{comment.author}</strong>: {comment.text}<br />
              <small>{comment.time}</small>
              <br />
              {comment.replies.map((reply, index) => (
                <div key={index} style={{ marginLeft: '20px' }}>
                  <strong>{reply.author}</strong>: {reply.text}<br />
                  <small>{reply.time}</small>
                </div>
              ))}
              {selectedCommentId === comment.id && (
                <div>
                  <Form.Group controlId="formReply">
                    <Form.Label>Reply</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your reply"
                      value={replyText}
                      onChange={(e) => setReplyText(e.target.value)}
                    />
                  </Form.Group>
                </div>
              )}
            </div>
          ))}
        </div>
        {selectedCommentId === null && (
          <Form>
            <Form.Group controlId="formBasicComment">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your comment"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {selectedCommentId === null ? (
          <Button variant="primary" onClick={handleAddComment}>
            Add Comment
          </Button>
        ) : (
          <Button variant="primary" onClick={handleAddReply}>
            Add Reply
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CommentModal;

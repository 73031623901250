

import { useState } from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import Login from './Components/Login/Login';
import ForgotPassword from './Components/Login/ForgotPassword';
import SignupForm from './Components/Signup/Signup';
import Home from './Components/Home/Home';

import Layout from './Components/Admin/Layout';
import Sidebar from './Components/Admin/Sidebar';
import Dashboard from './Components/Home/Dashboard';
import BidManagement from './Components/FormComponent/BidManagement';
import ProductManagement from './Components/FormComponent/ProductManagement';
import UserManagement from './Components/FormComponent/UserManagement';
import OtpVerificationForm from './Components/OtpForm/Otp';
import PasswordResetForm from './Components/PasswordResetFrom/PasswordResetFrom';
import ProductMaster from './Components/FormComponent/ProductMaster';
import Cincinnati from './Components/Warehouse/Cincinnati';
import Raleigh from './Components/Warehouse/Raleigh';
import Austin from './Components/Warehouse/Austin';
import Dallas from './Components/Warehouse/Dallas';


const App =() =>{

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Function to handle login
  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  // Function to handle logout
  const handleLogout = () => {
    setIsLoggedIn(false);
  };
  localStorage.setItem('userEmail', 'admin@example.com');
  sessionStorage.setItem('userType', 'Warehouse');


  return (



    <BrowserRouter>
    <Routes>
    <Route path='/' element={<Login/>}/>
       {/* <Route path="/signup" element={<SignupForm />} />  */}
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/otp-form" element={<OtpVerificationForm />} />
      <Route path="/reset-password" element={<PasswordResetForm />} />
      <Route path="/reset-password/:token" element={<PasswordResetForm />} />
   
    <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />}/>  
    <Route path="/sidebar" element={<ProtectedRoute element={<Sidebar />} />}/>  
    {/* <Route path="/dashbord" element={<ProtectedRoute element={<Dashbord />} />}/>   */}
    {/* <Route path="/bidManagement" element={<ProtectedRoute element={<BidManagement />} />}/>   */}
    <Route path="/productManagement" element={<ProtectedRoute element={<ProductManagement />} />}/>
    <Route path="/productMaster" element={<ProtectedRoute element={<ProductMaster />} />}/>  
    <Route path="/userManagement" element={<ProtectedRoute  element={<UserManagement/>}/>}/>
   <Route path="/warehouse" element={<ProtectedRoute  element={<Cincinnati/>}/>}/>
   {/* <Route path="/raleigh" element={<ProtectedRoute  element={<Raleigh/>}/>}/>
   <Route path="/dallas" element={<ProtectedRoute  element={<Dallas/>}/>}/>
   <Route path="/austin" element={<ProtectedRoute  element={<Austin/>}/>}/> */}
   
     
      
    </Routes>
  </BrowserRouter>


  );
}

export default App;

import React from 'react'
import './Home.css';
import  Sidebar from '../Admin/Sidebar.js';
const Dashboard = () => {
  return (
    <>
    <Sidebar/>
    <div id='main'>
    
    <div className="home-container bg-dark "   >

<div className="content" >
 
  

        <h1 style={{color:'white'}}>Dashboard</h1>
        
       </div>
        </div>
    </div>
    </>
  )
}

export default Dashboard
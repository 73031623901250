
// import React, { useState, useEffect } from 'react';
// import Sidebar from '../Admin/Sidebar';
// import axios from 'axios';
// import { Table } from 'react-bootstrap';

// const Raleigh = () => {
//   const [filteredData, setFilteredData] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');

//   // Fetch data from the API
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('https://luxeapi.emedha.in/API/v1/api/userbd');
//         setFilteredData(response.data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   // Handle search input change
//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//     // Filter data based on search term
//     const filteredResults = filteredData.filter(bid =>
//       bid.product.toLowerCase().includes(e.target.value.toLowerCase())
//     );
//     setFilteredData(filteredResults);
//   };

//   return (
//     <>
//       <Sidebar />
//       <div id='main'>
//         <h1>Raleigh Warehouse</h1>
        
//         {/* Search input */}
//         <div className="row justify-content-center mb-3">
//           <div className="col-lg-6">
//             <input
//               type="text"
//               className="form-control"
//               placeholder="Search by Product Name..."
//               value={searchTerm}
//               onChange={handleSearch}
//             />
//           </div>
//         </div>

//         {/* Table rendering */}
//         <div className="row justify-content-center mt-4">
//           <div className="col-lg-12">
//             <Table striped bordered hover responsive>
//               <thead>
//                 <tr>
//                   <th>ID</th>
//                   <th>Product Name</th>
//                   <th>Subcategory Product Type</th>
//                   <th>Dimension</th>
//                   <th>Color</th>
//                   <th>Bid Value</th>
//                   <th>Expected Price</th>
//                   <th>Quantity</th>
//                   <th>Remark</th>
//                   <th>Note</th>
//                   <th>Availability</th>
//                   <th>Start Bid Date</th>
//                   <th>Expire Bid Date</th>
//                   <th> Accept (Or) Reject</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {filteredData.map((bid, index) => (
//                   <tr key={bid.id}>
//                     <td>{index + 1}</td>
//                     <td>{bid.product}</td>
//                     <td>{bid.productCategory}</td>
//                     <td>{bid.dimension}</td>
//                     <td>{bid.color}</td>
//                     <td>{bid.bidvalue}</td>
//                     <td>{bid.expectedPrice}</td>
//                     <td>{bid.quantity}</td>
//                     <td>{bid.remark}</td>
//                     <td>{bid.note}</td>
//                     <td>{bid.availability === 1 ? 'Yes' : 'No'}</td>
//                     <td>{bid.startBidDate ? new Date(bid.startBidDate).toLocaleString() : 'Not selected'}</td>
//                     <td>{bid.expireBidDate ? new Date(bid.expireBidDate).toLocaleString() : 'Not selected'}</td>
//                     <td></td>
//                   </tr>
//                 ))}
//               </tbody>
//             </Table>
//           </div>
//         </div>

//         {/* Display message when no matching bids */}
//         {filteredData.length === 0 && searchTerm && (
//           <div className="row justify-content-center mt-4">
//             <div className="col-lg-6">
//               <div className="alert alert-info text-center" role="alert">
//                 No bids found.
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default Raleigh;

import React, { useState, useEffect } from 'react';
import Sidebar from '../Admin/Sidebar';
import axios from 'axios';
import { Table, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const Raleigh = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [acceptanceStatus, setAcceptanceStatus] = useState(new Map()); // Track acceptance status

  // Form state
  const [selectedBid, setSelectedBid] = useState('');
  const [status, setStatus] = useState('');

  // Request form state
  const [selectedRequestType, setSelectedRequestType] = useState('');
  const [comment, setComment] = useState('');
  const [submittedData, setSubmittedData] = useState({}); // Update to be an object with bid ID as a key

  // Handle request type selection
  const handleRequestTypeChange = (e) => {
    setSelectedRequestType(e.target.value);
  };

  // Handle comment change
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  // Handle request form submission
  const handleFormSubmitt = (e) => {
    e.preventDefault();
    if (selectedBid) {
      setSubmittedData(prev => ({
        ...prev,
        [selectedBid]: {
          requestType: selectedRequestType,
          comment
        }
      }));
    }
    // Clear form fields after submission
    setSelectedRequestType('');
    setComment('');
  };

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://luxeapi.emedha.in/API/v1/api/userbd');
        setFilteredData(response.data);
        // Initialize acceptance status based on data
        const statusMap = new Map(response.data.map(bid => [bid.id, bid.isAccepted]));
        setAcceptanceStatus(statusMap);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Handle search input change
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    // Filtering based on search term
    const filteredResults = filteredData.filter(bid =>
      bid.product.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  // Handle accept button click
  const handleAcceptClick = (id) => {
    // Update status to accepted
    setAcceptanceStatus(prev => {
      const newStatus = new Map(prev);
      newStatus.set(id, true);
      return newStatus;
    });
  };

  // Handle reject button click
  const handleRejectClick = (id) => {
    // Update status to rejected
    setAcceptanceStatus(prev => {
      const newStatus = new Map(prev);
      newStatus.set(id, false);
      return newStatus;
    });
  };

  // Handle form submission for bid status update
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const bid = filteredData.find(bid => bid.id === selectedBid);
    if (bid) {
      setAcceptanceStatus(prev => {
        const newStatus = new Map(prev);
        newStatus.set(selectedBid, status === 'accept');
        return newStatus;
      });
      setSubmittedData(prev => ({
        ...prev,
        [selectedBid]: {
          status
        }
      }));

      // Optionally clear form fields after submission
      setSelectedBid('');
      setStatus('');
    }
  };

  // Handle bid selection
  const handleBidSelect = (e) => {
    setSelectedBid(e.target.value);
  };

  return (
    <>
      <Sidebar />
      <div id='main'>
        <h3>Raleigh Warehouse</h3>
        
        {/* Search input */}
        <div className="row justify-content-end mb-3 ">
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Product Name..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>

        {/* Form for updating status */}
        <Form onSubmit={handleFormSubmit}>
          <Form.Group controlId="formBidSelect">
            <Form.Label>Select Product-Color/Design</Form.Label>
            <Form.Control
              as="select"
              value={selectedBid || ''}
              onChange={handleBidSelect}
            >
              <option value="">Select...</option>
              {filteredData.map(bid => (
                <option key={bid.id} value={bid.id}>
                  {bid.id} {bid.product} - {bid.productCategory}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formStatus">
            <Form.Label>Select Status</Form.Label>
            <Form.Control
              as="select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              disabled={!selectedBid}
            >
              <option value="">Select...</option>
              <option value="accept">Accept</option>
              <option value="reject">Reject</option>
            </Form.Control>
          </Form.Group>
          <Button className='mt-3' variant="primary" type="submit" disabled={!selectedBid}>
            Update Status
          </Button>
        </Form>

        {/* Table rendering */}
        <div className="row justify-content-center mt-4">
          <div className="col-lg-12">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Product Name</th>
                  <th>Subcategory Product Type</th>
                  <th>Dimension</th>
                  <th>Color</th>
                  <th>Bid Value</th>
                  <th>Expected Price</th>
                  <th>Quantity</th>
                  <th>Remark</th>
                  <th>Note</th>
                  <th>Availability</th>
                  <th>Start Bid Date</th>
                  <th>Expire Bid Date</th>
                
                  <th>Actions</th>
                  <th>Submitted Information</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((bid) => (
                  <tr key={bid.id}>
                    <td>{bid.id}</td>
                    <td>{bid.product}</td>
                    <td>{bid.productCategory}</td>
                    <td>{bid.dimension}</td>
                    <td>{bid.color}</td>
                    <td>{bid.bidvalue}</td>
                    <td>{bid.expectedPrice}</td>
                    <td>{bid.quantity}</td>
                    <td>{bid.remark}</td>
                    <td>{bid.note}</td>
                    <td>{bid.availability === 1 ? 'Yes' : 'No'}</td>
                    <td>{bid.startBidDate ? new Date(bid.startBidDate).toLocaleString() : 'Not selected'}</td>
                    <td>{bid.expireBidDate ? new Date(bid.expireBidDate).toLocaleString() : 'Not selected'}</td>
                  
                    <td>
                      <Button
                        variant="success"
                        onClick={() => handleAcceptClick(bid.id)}
                        disabled={acceptanceStatus.has(bid.id)}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleRejectClick(bid.id)}
                        disabled={acceptanceStatus.has(bid.id)}
                      >
                        Reject
                      </Button>
                    </td>
                    <td>
                      {submittedData[bid.id] ? (
                        <>
                          <p><strong>Request Type:</strong> {submittedData[bid.id].requestType}</p>
                          <p><strong>Comment:</strong> {submittedData[bid.id].comment}</p>
                        </>
                      ) : (
                        'No request submitted'
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Display message when no matching bids */}
        {filteredData.length === 0 && searchTerm && (
          <div className="row justify-content-center mt-4">
            <div className="col-lg-6">
              <div className="alert alert-info text-center" role="alert">
                No bids found.
              </div>
            </div>
          </div>
        )}
      </div>

      <div id='main'>
        <h1>Raise a Request</h1>
        
        <Form onSubmit={handleFormSubmitt}>
          <Form.Group controlId="formRequestType">
            <Form.Label>Select Request Type</Form.Label>
            <Form.Control
              as="select"
              value={selectedRequestType}
              onChange={handleRequestTypeChange}
            >
              <option value="">Select...</option>
              <option value="reorder">Reorder</option>
              <option value="productConcern">Product Concern</option>
              <option value="general">General</option>
            </Form.Control>
          </Form.Group>
          
          {selectedRequestType && (
            <Form.Group controlId="formComment">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comment}
                onChange={handleCommentChange}
                placeholder="Enter your comment here"
              />
            </Form.Group>
          )}

          <Button className='mt-3' variant="primary" type="submit">
            Submit Request
          </Button>
        </Form>

        {/* Display submitted data */}
        {submittedData && Object.keys(submittedData).length > 0 && (
          <div className="mt-4">
            <h3>Request Details</h3>
            {Object.keys(submittedData).map(bidId => (
              <div key={bidId}>
                <p><strong>Bid ID:</strong> {bidId}</p>
                <p><strong>Request Type:</strong> {submittedData[bidId].requestType}</p>
                <p><strong>Comment:</strong> {submittedData[bidId].comment}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Raleigh;

import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [resetPasswordMode, setResetPasswordMode] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate=useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Password reset email sent to:', email);
        setResetPasswordMode(true); 
    };

    const handleResetPassword = (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            alert("Passwords do not match.");
            return;
        }
        alert("password successfully created");
        navigate("/");
      
    };

    return (
        // <div className="mt-5 " >
        //     <div className="row justify-content-center">
        //         <div className="col-md-6 card">
        //             <h2>Forgot Password</h2>
        //             {!resetPasswordMode ? (
        //                 <form onSubmit={handleSubmit}>
        //                     <div>
        //                         <label htmlFor="email">Email address</label>
        //                         <input type="email" className="form-control" id="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        //                     </div>
        //                     <button type="submit" className="btn btn-primary mt-3">
        //                         Reset Password
        //                     </button>
        //                 </form>
        //             ) : (
        //                 <form onSubmit={handleResetPassword}>
        //                     <div>
        //                         <label htmlFor="newPassword">New Password</label>
        //                         <input type="password" className="form-control" id="newPassword"  placeholder="Enter new password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required/>
        //                     </div>
        //                     <div>
        //                         <label htmlFor="confirmPassword">Confirm Password</label>
        //                         <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm new password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
        //                     </div>
        //                     <button type="submit" className="btn btn-primary mt-3"> Save New Password </button>
        //                 </form>
        //             )}
        //             <p className="mt-3">
        //                 <Link to="/login">Back to Login</Link>
        //             </p>
        //         </div>
        //     </div>
        // </div>
        <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-4">
            <div className="card shadow-sm border-dark">
              <div className="card-body">
                <h2 className="card-title text-center mb-4">Forgot Password</h2>
                
                {!resetPasswordMode ? (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">
                      Reset Password
                    </button>
                  </form>
                ) : (
                  <form onSubmit={handleResetPassword}>
                    <div className="mb-3">
                      <label htmlFor="newPassword" className="form-label">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="newPassword"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Save New Password</button>
                  </form>
                )}
      
                <p className="text-center mt-3">
                  <Link to="/login" className="text-decoration-none">Back to Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ForgotPassword;

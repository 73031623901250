// import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './Login.css';
// import ReCAPTCHA from "react-google-recaptcha";
// import { Alert, Col, Row} from "react-bootstrap";

// const Login = () => {
//   const navigate = useNavigate(); // Hook to access navigation functions
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [rememberMe, setRememberMe] = useState(false);

//   const [formData, setFormData] = useState({
//     username: '',
//     email: '',
//     password: '',
//     confirmPassword: '',
//     mobileNumber: ''
//   });
//   const [formErrors, setFormErrors] = useState({
//     username: false,
//     email: false,
//     password: false,
//     confirmPassword: false,
//     mobile: false,
//   });
//   const [usersList, setUsersList] = useState([]);

// /************************************* */

// const [error, setError] = useState("");
// const [successMessage, setSuccessMessage] = useState("");

// const handleCaptchaChange = (token) => {
//   setCaptchaToken(token);
// };


// /************************************ */
//   // const handleInputChange = (e) => {
//   //   const { name, value } = e.target;
//   //   setFormData({
//   //     ...formData,
//   //     [name]: value
//   //   });
//   // };

//   const handleInputChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     // Clear the error state when user starts typing in the field
//     if (formErrors[e.target.name]) {
//       setFormErrors({ ...formErrors, [e.target.name]: false });
//     }
//   };
//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();

//   //   const { username, email, password, confirmPassword, mobileNumber } = formData;

//   //   if (password !== confirmPassword) {
//   //     alert("Passwords don't match!");
//   //     return;
//   //   }


//   //   const newUser = {
//   //     username,

//   //     email,
//   //     mobileNumber,
//   //     confirmPassword,
//   //     password,
//   //   };


//   //   setUsersList([...usersList, newUser]);

//   //   localStorage.setItem('userData', JSON.stringify(newUser));

//   //   alert("User registered successfully!");


//   //   setFormData({
//   //     username: '',
//   //     lname: '',
//   //     email: '',
//   //     password: '',
//   //     confirmPassword: '',
//   //     mobileNumber: ''
//   //   });
//   // };
//   const Navigate = useNavigate()

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const { uname, email, password, confirmPassword, mobileNumber } = formData;

//     if (password !== confirmPassword) {
//       alert("Passwords don't match!");
//       return;
//     }
//     let hasErrors = false;
//     const newErrors = { ...formErrors };

//     Object.keys(formData).forEach((key) => {
//       if (formData[key].trim() === '') {
//         newErrors[key] = true;
//         hasErrors = true;
//       } else {
//         newErrors[key] = false;
//       }
//     });

//     setFormErrors(newErrors);

//     if (hasErrors) {
//       setError('Please fill out all fields.');
//       return;
//     }

//     if (formData.password !== formData.confirmPassword) {
//       alert('Passwords do not match');
//       return;
//     }
//     try {
//       const formData = {
//         username: uname,
//         email,
//         password,
//         mobile: mobileNumber
//       };

//       // Make POST request to backend endpoint
//       const response = await axios.post('https://luxeapi.emedha.in/signup', formData);

//       // Check response status and handle accordingly
//       if (response.status === 200) {
//         alert(response.data.message); // Assuming backend sends back { message: 'User registered successfully...' }
//         navigate('/otp-form')
//         // Reset form data after successful registration
//         setFormData({
//           uname: '',
//           email: '',
//           password: '',
//           confirmPassword: '',
//           mobileNumber: ''
//         });
//         setFormErrors({
//           username: false,
//           email: false,
//           password: false,
//           confirmPassword: false,
//           mobile: false,
//         });
//       } else {
//         alert('Failed to register user. Please try again.'); // Handle non-200 status codes
//       }
//     } catch (error) {
//       // Handle error scenarios
//       if (error.response) {
//         console.error('Response error:', error.response); // Log detailed response error
//         alert(error.response.data.error); // Display backend error message if available
//       } else if (error.request) {
//         console.error('Request error:', error.request); // Log request error
//         alert('Failed to send request to server.'); // Notify user about request failure
//       } else {
//         console.error('Error:', error.message); // Log other errors
//         alert('Something went wrong. Please try again.'); // Generic error message
//       }
//     }
//     //  } catch (error) {
//     //   if (error.response && error.response.data.error) {
//     //     alert(error.response.data.error);
//     //   } else {
//     //     setError('Signup failed. Please try again.');
//     //   }
//     // }
//   };


// //Signup over 
// //Login start
//   /************************************** */
//   const [emailid, setEmailid] = useState('');

//   const [passwordVisible, setPasswordVisible] = useState(false); // State to handle password visibility
//   const [usersData, setUsersData] = useState([]);
// /******************************************** */

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handlePasswordChange = (e) => {
//     setPassword(e.target.value);
//   };

//   const handleCheckboxChange = (e) => {
//     setRememberMe(e.target.checked);
//   };
//   // const [error, setError] = useState("");
//   const [captchaToken, setCaptchaToken] = useState("");
//   const onChangeCaptcha = (token) => {
//     setCaptchaToken(token);
//   };
//   const onChange=() => {};
//  /********************************************** */ 
//   // useEffect(() => {
//   //   const fetchUsers = async () => {
//   //     try {
//   //       const response = await fetch('https://localhost:3009/login');
//   //       if (!response.ok) {
//   //         throw new Error('Network response was not ok');
//   //       }
//   //       const data = await response.json();
//   //       setUsersData(data);
//   //     } catch (error) {
//   //       console.error('Failed to fetch users:', error);
//   //     }
//   //   };

//   //   fetchUsers();
//   // }, []);
// /********************************** */
//   // const handleLogin = async (e) => {
//   //   e.preventDefault();

//   //   const storedUserData = JSON.parse(localStorage.getItem('userData'));

//   //   if (storedUserData) {
//   //     const { email: storedEmail, password: storedPassword } = storedUserData;

//   //     if (email === storedEmail && password === storedPassword) {
//   //       alert("Login successful!");
//   //       navigate('/'); // Navigate to home page after successful login
//   //     } else {
//   //       alert("Invalid credentials. Please try again.");
//   //     }
//   //   } else {
//   //     alert("User not found. Please sign up first.");
//   //   }
//   // };
//   const handleLogin = async () => {
//     const user = usersData.find(
//       (user) => user.email === emailid && user.password === password
//     );

//     if (user) {
//       sessionStorage.setItem('userType', user.user_type);
//       sessionStorage.setItem('user_name', user.UserName);
//       localStorage.setItem('userData', JSON.stringify(user));

//       switch(user.user_type) {
//         case 'admin':
//           navigate('/dashboard');
//           break;
//         case 'account':
//           navigate('/acdashboard');
//           break;
//         case 'sales':
//           navigate('/lodashboard');
//           break;
//         case 'Bidder':
//           navigate('/dashboard');
//           break;
//           case 'Warehouses1':
//           navigate('/dashboard');
//           break;
//           case 'Warehouses2':
//             navigate('/dashboard');
//             break;
//         default:
//           navigate('/login'); // Default redirect if user type is not matched
//       }

//       alert('Login successful!');
//     } else {
//       alert('Invalid credentials');
//     }
//   };


//   return (
//     <>
//       <div className="sectionn">
//         <div className="container">
//           <div className="row full-height justify-content-center">
//             <div className="col-12 text-center align-self-center py-5">
//               <div className="section pb-5 pt-5 pt-sm-2 text-center">
//                 <h6 className="mb-0 pb-3">
//                   <span>Log In</span><span>Sign Up</span>
//                 </h6>
//                 <input className="checkbox" type="checkbox" id="reg-log" name="reg-log" />
//                 <label htmlFor="reg-log"></label>
//                 <div className="card-3d-wrap mx-auto">
//                   <div className="card-3d-wrapper">
//                     <div className="card-front">
//                       <div className="center-wrap">
//                         <div className="section text-center">
//                           <h4 className="mb-4 pb-3">Log In</h4>
//                           <div className="form-group mb-4">
//                             <input type="email" name="logemail" className="form-style" style={{backgroundColor:'transparent'}}onChange={handleEmailChange} placeholder="Your Email" id="logemail"  />
//                             <i className="input-icon uil uil-at"></i>
//                           </div>
//                           <div className="form-group mb-4">
//                             <input type="password" name="logpass" className="form-style" onChange={handlePasswordChange} placeholder="Your Password" id="logpass"  />
//                             <i className="input-icon uil uil-lock-alt"></i>
//                           </div>
//                           <ReCAPTCHA
//     sitekey="6LeM4iIqAAAAAAIMwMuFR6XdhxhJfAcPzSF7dCpl"
//     // onChange={onChange}
//      className='mb-4'
//   />

//           {/* {error && <Alert variant="danger">{error}</Alert>} */}
//         <Row><Col>
//                           <p className='text-center'>
//                             <button type='submit' className='btn btn-danger' onClick={handleLogin}>Login</button>
//                           </p></Col>
//                           <Col>
//                           <p className="mb-0 mt-4 text-center">
//                             <Link to="/forgotpassword"><a className='' style={{color:'yellow'}}>Forgot Password?</a></Link>
//                           </p></Col>
//                           </Row>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="card-back">
//                       <div className="center-wrap">
//                         <div className="section text-center">
//                           <h4 className="">Sign Up</h4>
//                           <form onSubmit={handleSubmit}>
//                             <div className='form-group mb-4'>
//                               <input type='text' placeholder='Enter First Name' className='form-style' id='username' name='username' value={formData.username} onChange={handleInputChange} required />
//                             </div>
//                             <div className='form-group mb-4'>
//                               <input type='email' placeholder='Enter Email' className='form-style' id='email' name='email' value={formData.email} onChange={handleInputChange} required />
//                             </div>
//                             <div className='form-group mb-4'>
//                               <input type='number' placeholder='Enter Mobile Number' className='form-style' id='mobileNumber' name='mobileNumber' value={formData.mobileNumber} onChange={handleInputChange} required />
//                             </div>
//                             <div className='form-group mb-4'>
//                               <input type='password' placeholder='Enter Password' className='form-style' id='password' name='password' value={formData.password} onChange={handleInputChange} required />
//                             </div>
//                             <div className='form-group mb-4'>
//                               <input type='password' placeholder='Confirm Password' className='form-style' id='confirmPassword' name='confirmPassword' value={formData.confirmPassword} onChange={handleInputChange} required />
//                             </div>
//                             <p className='text-center'>
//                               <button className='btn btn-danger'>Sign up</button>
//                             </p>
//                           </form>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Login;


import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import ReCAPTCHA from "react-google-recaptcha";
import { Alert, Col, Row } from "react-bootstrap";
import AGSTONESLOGO from '../images/AGSTONESLOGO.png';
import GraniteQuarry from './GraniteQuarry.jpeg';


const Login = () => {

  const styles = {
    body: {
      margin: '0px',
      fontFamily: 'Arial, sans-serif',
      backgroundImage: `url(${GraniteQuarry})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    loginContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundColor: 'rgba(75, 75, 75, 0.5)',
    },
    loginBox: {
      position: 'relative',
      backgroundColor: 'white',
      borderRadius: '0.5rem',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      padding: '2rem',
      width: '100%',
      maxWidth: '400px',
    },
    loginHeader: {
      position: 'absolute',
      top: '-0.5rem',
      width:'70%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#f5cc19', // Tailwind's pink-500
      color: 'black',
      padding: '0.5rem 1rem',
      borderRadius: '0.5rem',
      zIndex: 10,
      textAlign: 'center',
    },
    logoContainer: {
      textAlign: 'center',
      margin: '1rem 0',
    },
    logo: {
      margin: '1rem auto',
    },
    inputGroup: {
      marginBottom: '1rem',
    },
    inputField: {
      width: '100%',
      padding: '0.5rem 1rem',
      border: '1px solid #ccc',
      borderRadius: '0.5rem',
      outline: 'none',
    },
    submitButton: {
      width: '100%',
      backgroundColor: '#D53F8C', // Tailwind's pink-500
      color: 'white',
      padding: '0.5rem',
      borderRadius: '0.5rem',
      cursor: 'pointer',
    },
    submitButtonHover: {
      backgroundColor: '#B83D72', // Darker shade
    },
    signupPrompt: {
      textAlign: 'center',
      fontSize: '0.875rem',
      margin: '1rem 0',
    },
    signupLink: {
      color: '#D53F8C', // Tailwind's pink-500
      fontWeight: 'bold',
    },
    forgotPassword: {
      textAlign: 'center',
      fontSize: '0.875rem',
    },
    forgotLink: {
      color: '#1E90FF', // Tailwind's blue-500
    },
    footer: {
      textAlign: 'center',
      color: 'white',
      fontSize: '0.875rem',
      marginTop: '1rem',
    },
    footerBold: {
      fontWeight: 'bold',
    },
  };














  const navigate = useNavigate(); // Hook to access navigation functions
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    mobileNumber: ''
  });

  const [usersList, setUsersList] = useState([]);

  /************************************* */

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };




  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const Navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { username, email, password, confirmPassword, mobileNumber } = formData;

    if (password !== confirmPassword) {
      alert("Passwords don't match!");
      return;
    }

    try {
      const newUser = {
        username,
        email,
        password,
        confirmPassword,
        mobile: mobileNumber
      };

      // Make POST request to backend endpoint
      const response = await axios.post('https://luxeapi.emedha.in/signup', newUser);

      // Check response status and handle accordingly
      if (response.status === 200) {
        alert(response.data.message); // Assuming backend sends back { message: 'User registered successfully...' }
        Navigate('/otp-form')
        // Reset form data after successful registration
        setFormData({
          uname: '',
          email: '',
          password: '',
          confirmPassword: '',
          mobileNumber: ''
        });
      } else {
        alert('Failed to register user. Please try again.'); // Handle non-200 status codes
      }
    } catch (error) {
      // Handle error scenarios
      if (error.response) {
        console.error('Response error:', error.response); // Log detailed response error
        alert(error.response.data.error); // Display backend error message if available
      } else if (error.request) {
        console.error('Request error:', error.request); // Log request error
        alert('Failed to send request to server.'); // Notify user about request failure
      } else {
        console.error('Error:', error.message); // Log other errors
        alert('Something went wrong. Please try again.'); // Generic error message
      }
    }
  };




  //Signup over 
  //Login start
  /************************************** */
  const [emailid, setEmailid] = useState('');

  const [passwordVisible, setPasswordVisible] = useState(false); // State to handle password visibility
  const [usersData, setUsersData] = useState([]);
  /******************************************** */

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setRememberMe(e.target.checked);
  };
  // const [error, setError] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const onChangeCaptcha = (token) => {
    setCaptchaToken(token);
  };
  const onChange = () => { };
  /********************************************** */
  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     try {
  //        const response = await fetch('https://luxeapi.emedha.in/API/v1/api/usersd');
  //     // const response = await fetch('http://localhost:3009/api/usersd');

  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       const data = await response.json();
  //       setUsersData(data);
  //     } catch (error) {
  //       console.error('Failed to fetch users:', error);
  //     }
  //   };

  //   fetchUsers();
  // }, []);
  // console.log(usersData)

  // const handleLogin = async () => {
  //   const user = usersData.find(
  //     (user) => user.email === email && user.password === password
  //   );

  //   if (user) {
  //     sessionStorage.setItem('usertype', user.usertype);
  //     sessionStorage.setItem('userName', user.userName);
  //     localStorage.setItem('userData', JSON.stringify(user));

  //     switch(user.usertype) {
  //       case 'Admin':
  //         navigate('/dashboard');
  //         break;
  //       case 'Sales':
  //         navigate('/productManagement');
  //         break;
  //       case 'Bidder':
  //         navigate('/bidManagement');
  //         break;
  //         case 'Warehouses1':
  //         navigate('/bidManagement');
  //         break;
  //         case 'Product':
  //           navigate('/productManagement');
  //           break;
  //       default:
  //         navigate('/'); // Default redirect if user type is not matched
  //     }

  //     alert('Login successful!');
  //   } else {
  //     alert('Invalid credentials');
  //   }
  // };
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault()
    setLoading(true);
    try {
      const response = await fetch('https://luxeapi.emedha.in/API/v1/api/usersd');

      // const response = await fetch('http://localhost:3009/api/usersd');


      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const usersData = await response.json();
      console.log(usersData)


      const user = usersData.find(
        (user) => user.email === email && user.password === password
      );

      console.log(user)

      if (user) {
        sessionStorage.setItem('usertype', user.usertype);
        sessionStorage.setItem('userName', user.userName);
        localStorage.setItem('userData', JSON.stringify(user));
        sessionStorage.setItem('warehouseEmail', email);

        switch (user.usertype) {
          case 'Admin':
            navigate('/dashboard');
            break;
          case 'Product':
            navigate('/productManagement');
            break;
          // case 'Bidder':
          // //navigate('/bidManagement');
          //   break;
          case 'WareHouse':
            navigate('/warehouse');
            break;
          // case 'Raleigh':
          //   navigate('/raleigh');
          //   break;
          //   case 'Dallas':
          //     navigate('/dallas');
          //     break;
          //     case 'Austin':
          //       navigate('/austin');
          // break;


          default:
            navigate('/'); // Default redirect if user type is not matched
        }

        // alert('Login successful!');
      } else {
        alert('Invalid credentials');
      }
    } catch (error) {
      console.error('Failed to fetch users:', error);
      alert('Error during login, please try again later.');
    } finally {
      setLoading(false);
    }
  };


  return (
    //   <>
    //     <div className="sectionn">
    //       <div className="container">
    //         <div className="row full-height justify-content-center">
    //           <div className="col-12 text-center align-self-center ">
    //             <div className="section  text-center">
    //               {/* <h6 className="mb-0 ">
    //                 <span>Log In</span>
    //                 <span>Sign Up</span>
    //               </h6> */}
    //               {/* <input className="checkbox" type="checkbox" id="reg-log" name="reg-log" /> */}
    //               <label htmlFor="reg-log"></label>

    //               <div className="card-3d-wrap mx-auto">

    //                 <div className="card-3d-wrapper">

    //                   <div className="card-front " >
    //                   <h4 className="header">Log In</h4>
    //                     <div className="center-wrap">
    //                       <div className="section text-center">

    //                         <div className="form-group mb-4">
    //                           <input type="email" name="logemail" className="form-style" style={{backgroundColor:'transparent'}}onChange={handleEmailChange} placeholder="Your Email" id="logemail"  />
    //                           <i className="input-icon uil uil-at"></i>
    //                         </div>
    //                         <div className="form-group mb-4">
    //                           <input type="password" name="logpass" className="form-style" onChange={handlePasswordChange} placeholder="Your Password" id="logpass"  />
    //                           <i className="input-icon uil uil-lock-alt"></i>
    //                         </div>
    //                         <ReCAPTCHA
    //   sitekey="6LeM4iIqAAAAAAIMwMuFR6XdhxhJfAcPzSF7dCpl"
    //   // onChange={onChange}
    //    className='mb-4'
    // />

    //         {/* {error && <Alert variant="danger">{error}</Alert>} */}
    //       <Row><Col>
    //                         <p className='text-center'>
    //                           <button type='submit' className='btn btn-danger' onClick={handleLogin}>Login</button>
    //                         </p></Col>
    //                         <Col>
    //                         <p className="mb-0 mt-4 text-center">
    //                           <Link to="/forgotpassword"><a className='' style={{color:'black'}}>Forgot Password?</a></Link>
    //                         </p></Col>
    //                         </Row>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="card-back">
    //                     <div className="center-wrap">
    //                       <div className="section text-center">
    //                         <h4 className="">Sign Up</h4>
    //                         <form onSubmit={handleSubmit}>
    //                           <div className='form-group mb-4'>
    //                             <input type='text' placeholder='Enter First Name' className='form-style' id='username' name='username' value={formData.username} onChange={handleInputChange} required />
    //                           </div>
    //                           <div className='form-group mb-4'>
    //                             <input type='email' placeholder='Enter Email' className='form-style' id='email' name='email' value={formData.email} onChange={handleInputChange} required />
    //                           </div>
    //                           <div className='form-group mb-4'>
    //                             <input type='number' placeholder='Enter Mobile Number' className='form-style' id='mobileNumber' name='mobileNumber' value={formData.mobileNumber} onChange={handleInputChange} required />
    //                           </div>
    //                           <div className='form-group mb-4'>
    //                             <input type='password' placeholder='Enter Password' className='form-style' id='password' name='password' value={formData.password} onChange={handleInputChange} required />
    //                           </div>
    //                           <div className='form-group mb-4'>
    //                             <input type='password' placeholder='Confirm Password' className='form-style' id='confirmPassword' name='confirmPassword' value={formData.confirmPassword} onChange={handleInputChange} required />
    //                           </div>
    //                           <p className='text-center'>
    //                             <button className='btn btn-danger'>Sign up</button>
    //                           </p>
    //                         </form>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </>

    <div style={styles.body}>
      <div style={styles.loginContainer}>
        <div style={styles.loginBox}>
          <div style={styles.loginHeader}>
            <h2>Login</h2>
          </div>
          <div style={styles.logoContainer}>
            <img
              alt="SRU logo"
              style={styles.logo}
              src={AGSTONESLOGO}
              width="100"
            />
          </div>
          <form>
            <div style={styles.inputGroup}>
              <input
                style={styles.inputField}
                name="logemail"
                className="form-style"
                onChange={handleEmailChange}
                placeholder="Your Email"
                id="logemail"
              />
            </div>
            <div style={styles.inputGroup}>
              <input
                style={styles.inputField}
                type="password"
                name="logpass"
                className="form-style"
                onChange={handlePasswordChange}
                placeholder="Your Password"
                id="logpass"
              />
            </div>
            <ReCAPTCHA
              sitekey="6LeM4iIqAAAAAAIMwMuFR6XdhxhJfAcPzSF7dCpl"
              // onChange={onChange}
              className='mb-4'
            />

            <Row>
              <Col>
            <div >
              <p>
              <button
                // style={styles.submitButton}
                // onMouseEnter={(e) => e.target.style.backgroundColor = styles.submitButtonHover.backgroundColor}
                // onMouseLeave={(e) => e.target.style.backgroundColor = styles.submitButton.backgroundColor}
                type="submit"
                className='btn btn-danger'
                onClick={handleLogin}
              >
                Login
              </button>
              </p>

              
            </div>
            </Col>  
            <Col>
            {/* <div style={styles.forgotPassword}>
              <a style={styles.forgotLink} href="/forgotpassword">
                <i className="fas fa-flag" style={{color:'black'}}></i> Forgot Password?
              </a>
            </div> */}
            </Col>
            </Row>
          </form>
        </div>
      </div>

    </div>


  );
};

export default Login;
